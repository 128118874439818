<template>
  <div class="portfolio">
    <h1 class="heading__main">Portfolio</h1>

    <div class="container">
      <Gallery :galleryInput="portfolio" />
    </div>
  </div>
</template>

<script>
import { computed, ref} from 'vue'
import { SortOrder } from '@kentico/kontent-delivery'
import { Client } from "@/client"
import Gallery from '@/components/gallery/Gallery.vue'

import './portfolio.scss'

export default {
  name: "Portfolio",
  components: {
    Gallery
  },
  data() {
    return {
      showModal: false,
      loadImage: "",
      loadAlt: ""
    }
  },
  setup() {
    const gallery = ref([])

    Client.items()
      .type('photo')
      .orderParameter('elements.date_taken', SortOrder.desc)
      .toObservable()
      .subscribe(response => gallery.value = response.items)

    const portfolio = computed(() => gallery.value.map(item => ({ 
      image: item.photo.value[0].url,
      alt: item.photo.value[0].description,
    })))

    return {
      portfolio
    }
  },
  methods: {
    openModal(item) {
      this.showModal = true,
      this.loadImage = item.image,
      this.loadAlt = item.alt
    }
  }
}
</script>